import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/PageLayout.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const CLHbid = makeShortcode("CLHbid");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Bidding Process`}</h1>
    <p>{`The online tender platform will commence at a predetermined time, which will be
posted in advance on the online page for that specific auction. The bidding page
will also indicate the bid increments for each specific auction. The clock will
indicate the time remaining in the auction for that specific parcel.`}</p>
    <p><strong parentName="p">{`Please note:`}</strong>{` `}<CLHbid mdxType="CLHbid" />{` encourages you to place your bids well in advance as
each Parcel has a start and end time.`}</p>
    <p>{`In auctions involving multiple parcels, each parcel will either:`}</p>
    <ol className="mb-4 list-decimal list-inside">
  <li>
    Have a different expiration time to allow you to focus on one parcel at a
    time, OR
  </li>
  <li>Close universally thus every parcel will expire at the same time.</li>
    </ol>
    <p><strong parentName="p">{`Please note:`}</strong>{` The expiration time on any Parcel being sold has a refresh
setting. If a bid is placed in the last two minutes of the auction, an
additional two minutes will be added to the clock. Parcels will close only when
there have been no bids for the last two minutes.`}</p>
    <p>{`Other bidders are not privy to who is the highest bidder on any parcel.`}</p>
    <p>{`The chronological order in which the parcels sell will be displayed on the
parcel details page of the website. If there are any questions at all regarding
a sale or the time expiration thereof, please do not hesitate to call us at `}<a href="tel:1-866-263–7480">{`+1 (866) 263–7480`}</a>{`.`}</p>
    <p>{`For tips on how to register, please watch the video below:`}</p>
    <div className="embed-responsive aspect-ratio-16-9">
  <iframe className="embed-responsive-item" src="https://www.youtube.com/embed/F93929-_8yQ" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      